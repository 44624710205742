/* eslint-disable indent */
/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import Contrast from 'get-contrast'
import { HexColorPicker } from 'react-colorful'
import { useQueryParamString } from 'react-use-query-param-string'
import { theme } from '../styles'
import hexToHSL from '../utils/hexToHSL.js'
import hslToHex from '../utils/hslToHex.js'
import copyTextToClipboard from '../utils/copyTextToClipboard.js'

import { PersonIcon } from '../../static/icons/component-icons/PersonIcon.js'
import CrossIcon from '../../static/icons/misc-icons/cross-icon-gray700.svg'
import colorPickerCard from '../../static/icons/misc-icons/color-picker-card.svg'
import greenCheckIcon from '../../static/icons/check-icons/green700-check-icon.svg'
import errorIcon from '../../static/icons/misc-icons/error-icon.svg'
import swapIcon from '../../static/icons/misc-icons/swap-arrows.svg'
import contrastIcon from '../../static/icons/misc-icons/contrast-icon.png'
import pointerCardIcon from '../../static/icons/misc-icons/pointer-card.svg'
import rightPointerCardIcon from '../../static/icons/misc-icons/right-pointer-card.svg'
import shareIconPurple from '../../static/icons/misc-icons/share-icon-purple500.svg'
import shareIconOffBlack from '../../static/icons/misc-icons/share-icon-offBlack.svg'

import Input from './form/Input'
import ColorContrastCheckerForm from './common/ColorContrastCheckerForm'
import Tooltip from './common/Tooltip'
import Text from './common/Text'
import Tag from './common/Tag'
import Space from './common/Space'
import Button from './common/Button'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 80px 0px;
  position: relative;

  :after {
    ${props =>
      props.showGate &&
      `content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(241, 242, 246, 0.7);
        z-index: 1;
    `}
  }

  @media (max-width: 800px) {
    width: 100%;
    margin: 0 auto;
  }
`

const BG = styled.div`
  position: absolute;
  width: 500vw;
  margin-left: -300%;
  height: 100%;
  z-index: -1;
  background-color: ${theme.color.offWhite};
  margin-top: -80px;

  @media (max-width: 1250px) {
    margin-left: -100%;
  }
`

const ScoreWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto auto auto auto auto auto;
  grid-gap: 24px;

  @media (max-width: 800px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`

const Card = styled.div`
  display: grid;
  background-color: ${theme.color.white};
  border-radius: 16px;
  box-shadow: 0px 0px 14px 3px ${theme.color.dropShadow};
  padding: 32px;
  position: relative;

  @media (max-width: 800px) {
    width: 100%;
    padding: 32px;
  }

  * {
    transition: all 150ms ease-in-out;
  }
`

const ColorInputs = styled(Card)`
  grid-column: 1 / 3;
  grid-row: 1 / 3;
  grid-auto-flow: column;
  grid-gap: 32px;
  align-items: center;
  grid-template-columns: 2fr 1fr 2fr;

  input {
    text-transform: uppercase;
  }

  @media (max-width: 800px) {
    grid-gap: 16px;
    grid-template-columns: 1fr;
    grid-auto-flow: row;
    order: 3;
  }
`

const ColorInputWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  height: fit-content;
  position: relative;

  label {
    margin-bottom: 0px;
  }

  .popover {
    position: absolute;
    bottom: 16px;
    right: -5px;
    z-index: 9999;
  }

  .react-colorful {
    background: url(${colorPickerCard});
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
    width: 224px;
    height: 224px;
    padding: 14%;
    padding-bottom: 23%;
    padding-top: 18%;
  }
`

const ColorPicker = styled.button`
  position: absolute;
  right: 10px;
  bottom: 11px;
  border: 1px solid ${theme.color.gray300};
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${p => p.valueColor || theme.color.white};
  padding: 0px;
`

const CloseButton = styled.button`
  position: absolute;
  top: 22px;
  right: 13px;
  padding: 4px;
  background: none;
  display: flex;
  z-index: 999999;
`

const CloseIcon = styled.img`
  width: 11px;
  height: 11px;
`

const SwapButton = styled.button`
  background-color: transparent;
  align-self: center;
  justify-self: center;
  border-radius: 8px;
  padding: 8px;
  margin-bottom: -8px;
  width: max-content;

  &:hover,
  &:focus {
    box-shadow: 0px 0px 14px 3px ${theme.color.dropShadow};
    * {
      color: ${theme.color.offBlack};
    }
  }
  @media (max-width: 800px) {
    align-self: center;
  }
`

const SwapBtnIcon = styled.img`
  margin-bottom: 2px;

  @media (max-width: 800px) {
    transform: rotate(90deg);
    margin: 6px 0px;
  }
`

const OverviewPreview = styled(Card)`
  grid-column: 3 / 4;
  grid-row: 2 / 5;
  grid-gap: 16px;
  background-color: ${p => p.backgroundColor || theme.color.white};
  filter: ${p => (p.grayscale ? 'grayscale(100%)' : 'grayscale(0)')};

  * {
    color: ${p => p.contentColor || theme.color.offBlack};
    border-color: ${p => p.contentColor || theme.color.offBlack};
  }

  @media (max-width: 800px) {
    order: 2;
  }
`

const Ratio = styled(Card)`
  grid-column: 1 / 2;
  grid-row: 3 / 5;
  grid-gap: 16px;

  .button {
    display: grid;
    grid-auto-flow: column;
    grid-column-gap: 12px;
    align-items: center;

    &::before {
      content: url(${shareIconPurple});
      height: 24px;
    }

    &:hover,
    &:focus {
      &::before {
        content: url(${shareIconOffBlack});
      }
      p {
        color: ${theme.color.offBlack};
      }
    }

    @media (max-width: 1250px) {
      min-width: unset;
      width: auto;

      p {
        white-space: normal;
      }
    }
  }

  @media (max-width: 800px) {
    order: 3;
  }
`

const RatioText = styled(Text)`
  font-size: 3.8rem;
  font-family: ${theme.fonts.family.heading1};
  font-weight: ${theme.fonts.weight.heading1};
  letter-spacing: ${theme.fonts.letterSpacing.smallCaps};

  span {
    font-size: 3.2rem;
    font-family: ${theme.fonts.family.body};
    font-weight: ${theme.fonts.weight.body};
    letter-spacing: ${theme.fonts.letterSpacing.smallCaps};
    color: ${theme.color.gray700};
  }
`

const TwoCol = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  height: fit-content;
  align-items: center;
`

const SmallTwoCol = styled(TwoCol)`
  @media (max-width: 1200px) {
    display: grid;
    justify-content: center;
    grid-gap: 8px;

    div {
      margin-left: 0px;
    }
  }

  @media (max-width: 1250px) {
    justify-content: flex-start;
  }
`

const SmallTwoColSingle = styled(SmallTwoCol)`
  justify-content: flex-start;
  gap: 12px;
`

const SubTwoCol = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 32px;
`

const ComplianceLevel = styled(Card)`
  grid-column: 2 / 3;
  grid-row: 3 / 5;
  grid-gap: 16px;

  @media (max-width: 800px) {
    order: 3;
    grid-gap: 24px;
  }
`

const GrayscaleControl = styled.div`
  grid-column: 3 / 4;
  grid-row: 1 / 1;
  grid-gap: 16px;
  padding: 24px 32px;
  display: flex;
  align-items: center;

  @media (max-width: 800px) {
    order: 1;
  }
`

const GrayscaleToggle = styled.button`
  min-width: 50px;
  height: 26px;
  border: 2px solid ${theme.color.purple500};
  border-radius: 15px;
  background-color: transparent;
  margin-top: 3px;
  display: flex;
  align-items: center;
  padding: 4px;
  transition: padding-left 100ms ease-in-out;
  padding-left: ${p => (p.grayscale ? '26px' : '4px')};

  ::before {
    content: '';
    width: 16px;
    height: 16px;
    background-color: ${theme.color.purple500};
    border-radius: 100%;
  }
`

const RatioScale = styled(Card)`
  grid-column: 1 / -1;
  grid-row: 5 / -1;
  grid-gap: 96px;

  @media (max-width: 800px) {
    order: 4;
    grid-gap: 16px;
  }
`

const ScaleGradientWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 18fr 1fr;
  grid-gap: 8px;

  @media (max-width: 800px) {
    margin-top: 0;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto auto;
    grid-gap: 16px;
    grid-row: 2 / 3;
    grid-column: 1/ -1;
    margin: 0 auto;
  }
`

const ScaleGradientRange = styled.div`
  height: 20px;
  background-color: ${theme.color.gray300};
  border-radius: 20px;
  position: relative;

  @media (max-width: 800px) {
    width: 20px;
    height: 100vw;
    background-image: ${theme.color.gray300};
    border-radius: 20px;
    grid-row: 2 / 3;
    grid-column: 1 / 2;
    justify-self: flex-end;
  }
`

const ScaleGradientFill = styled.div`
  height: 20px;
  width: ${p => (p.ratio ? `${p.ratio}%` : '100%')};
  background-image: linear-gradient(to right, ${theme.color.green500}, ${theme.color.purple500});
  border-radius: 20px;

  @media (max-width: 800px) {
    width: 20px;
    height: ${p => (p.ratio ? `${p.ratio}%` : '100%')};
    background-image: linear-gradient(to bottom, ${theme.color.green500}, ${theme.color.purple500});
    border-radius: 20px;
  }
`

const LowWrapper = styled.div`
  display: grid;
  justify-items: center;

  @media (max-width: 800px) {
    grid-row: 1 / 2;
    grid-column: 1 / -1;
    grid-auto-flow: column;
    align-items: center;
    grid-gap: 8px;
  }
`

const HighWrapper = styled.div`
  display: grid;
  justify-items: center;

  @media (max-width: 800px) {
    grid-row: 3 / 4;
    grid-column: 1 / -1;
    grid-auto-flow: column;
    align-items: center;
    grid-gap: 8px;
  }
`

const RatingWrapper = styled.div`
  display: grid;
  grid-column: 2 / 3;
  margin-top: -24px;
  grid-template-columns: 12% 7% 13% 61% 7%;
  padding: 0px 4px;

  @media (max-width: 800px) {
    margin-top: 0px;
    padding: 0px;
    padding-top: 4px;
    grid-template-columns: 1fr;
    grid-template-rows: 12% 7% 13% 61% 7%;
    grid-column: 2/ -1;
    grid-row: 2 / 3;
  }
`

const Rating = styled.div``

const Rating5 = styled(Rating)`
  text-align: right;

  @media (max-width: 800px) {
    text-align: left;
    margin-top: 8px;
  }
`

const YourScoreBox = styled.div`
  background-image: url(${pointerCardIcon});
  background-repeat: no-repeat;
  background-size: 100%;
  width: 100px;
  height: 90px;
  padding-top: 8px;
  text-align: center;
  margin: 0px;
  position: absolute;
  left: ${p => `calc(${p.ratio}% - 56px)`};
  bottom: 20px;

  @media (max-width: 800px) {
    background-image: url(${rightPointerCardIcon});
    margin: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 0px;
    padding-right: 13px;
    position: absolute;
    top: ${p => `calc(${p.ratio}% - 50px)`};
    right: 24px;
    left: unset;
    bottom: unset;
  }
`

const LuminosityInputWrapper = styled.div`
  display: flex;
  margin-top: 16px;
  padding: 0px 8px;
  position: relative;
`

const Luminosity = styled.input`
  width: 100%;
  margin-left: 8px;
  -webkit-appearance: none;

  &::-webkit-slider-runnable-track {
    height: 7px;
    width: 100%;
    background: linear-gradient(to right, ${theme.color.offBlack}, ${theme.color.gray100});
    border-radius: 7px;
  }

  &::-moz-range-track {
    height: 7px;
    width: 100%;
    background: linear-gradient(to right, ${theme.color.offBlack}, ${theme.color.gray100});
    border-radius: 7px;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 15px;
    width: 15px;
    border-radius: 100%;
    background: ${theme.color.offBlack};
    transform: translateY(-25%);
  }

  &::-moz-range-thumb {
    -webkit-appearance: none;
    height: 15px;
    width: 15px;
    border-radius: 100%;
    background: ${theme.color.offBlack};
    transform: translateY(0%);
  }

  &::-ms-thumb {
    -webkit-appearance: none;
    height: 15px;
    width: 15px;
    border-radius: 100%;
    background: ${theme.color.offBlack};
    transform: translateY(0%);
  }
`

const FakeInput = styled.input`
  border-width: 2px;
  border-style: solid;
  border-radius: 8px;
  height: 48px;
  width: 100%;
  background-color: transparent;
`

const GatedForm = styled.div`
  // this is done this way because the browser stops the form submission if the dom node that started the request is removed
  ${props => !props.showGate && `display: none;`}
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 50px 100px 5px rgba(50, 50, 93, 0.25), 0 30px 60px -10px rgba(0, 0, 0, 0.3);
  border: 1px solid #e4e6ea;
  padding: 32px;
  position: absolute;
  top: 150px;
  left: calc(50% - 275px);
  width: 550px;
  z-index: 2;

  @media (max-width: 1250px) {
    left: calc(50% - 200px);
    width: 400px;
  }

  @media (max-width: 425px) {
    left: calc(50% - 175px);
    width: 350px;
  }

  @media (max-width: 375px) {
    left: calc(50% - 150px);
    width: 300px;
  }
`

const ContrastChecker = () => {
  const [ratio, setRatio] = useState()
  const [level, setLevel] = useState('GREAT')
  const [pass, setPass] = useState(true)
  const [valueA, setValueA] = useState('#000000')
  const [valueB, setValueB] = useState('#FFFFFF')
  const [luminosityA, setLuminosityA] = useState('0')
  const [luminosityB, setLuminosityB] = useState('255')
  const [grayscale, setGrayscale] = useState(false)
  const [isPickerAOpen, setIsPickerAOpen] = useState(false)
  const [isPickerBOpen, setIsPickerBOpen] = useState(false)
  const [isCopied, setIsCopied] = useState(false)
  const [showGate, setShowGate] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [queryParamToGate, setQueryParamToGate, initialized] = useQueryParamString('cc_gate', false)
  const windowGlobal = typeof window !== `undefined` && window

  useEffect(() => {
    if (
      initialized &&
      queryParamToGate === 'check' &&
      windowGlobal &&
      window.localStorage.getItem('color_contrast_checker_form_submitted') !== 'true'
    ) {
      setShowGate(true)
    }
  }, [initialized, queryParamToGate])

  useEffect(() => {
    const url = new URL(windowGlobal && windowGlobal.location)
    const params = new URLSearchParams(url.search)
    const foregroundColor = params.get('f_color')
    const backgroundColor = params.get('b_color')
    if (foregroundColor) setValueA(`#${foregroundColor}`)
    if (backgroundColor) setValueB(`#${backgroundColor}`)
  }, [])

  const calculateRatio = (a, b) => {
    const newRatio = Contrast.ratio(a, b).toString().split('.')
    const prettyRatio = newRatio.length > 1 ? `${newRatio[0]}.${newRatio[1].substring(0, 1)}` : newRatio[0]
    setRatio(prettyRatio)
    setPass(Contrast.isAccessible(a, b))
    determineLevel(ratio)
  }

  useEffect(() => {
    if (valueA.length === 7 && valueB.length === 7) {
      const hslA = hexToHSL(valueA)
      const hslB = hexToHSL(valueB)
      const luminosityValA = hslA.split(',')[2].split('%')[0]
      const luminosityValB = hslB.split(',')[2].split('%')[0]
      setLuminosityA(luminosityValA)
      setLuminosityB(luminosityValB)
      calculateRatio(hslA, hslB)
    }
  }, [valueA, valueB, ratio])

  const handleInput = (input, aOrB) => {
    const isValidHex = new RegExp('^[A-Fa-f0-9#]*$')
    if (isValidHex.test(input)) {
      aOrB === 'A' ? setValueA(input) : setValueB(input)
    }
  }

  const handleLuminosity = (val, aOrB) => {
    aOrB === 'A' ? setLuminosityA(val) : setLuminosityB(val)
    const hslVal = aOrB === 'A' ? hexToHSL(valueA) : hexToHSL(valueB)
    const newHsl = `hsl(${hslVal.split(',')[0].split('(')[1]}, ${hslVal.split(',')[1]}, ${val}%)`
    aOrB === 'A' ? setValueA(hslToHex(newHsl)) : setValueB(hslToHex(newHsl))
  }

  const determineLevel = contrastRatio => {
    if (contrastRatio < 3) setLevel('POOR')
    if (contrastRatio >= 3 && contrastRatio < 4.5) setLevel('OKAY')
    if (contrastRatio >= 4.5 && contrastRatio < 7) setLevel('GOOD')
    if (contrastRatio >= 7 && contrastRatio <= 21) setLevel('GREAT')
  }

  const handleShare = () => {
    const queryParams = new URLSearchParams(window.location.search)
    if (valueA && valueB) {
      queryParams.set('f_color', `${valueA.split('#')[1]}`)
      queryParams.set('b_color', `${valueB.split('#')[1]}`)
      window.history.replaceState({}, '', `?${queryParams.toString()}`)

      const url = new URL(windowGlobal && windowGlobal.location)
      copyTextToClipboard(url).then(() => {
        setIsCopied(true)
        setTimeout(() => {
          setIsCopied(false)
        }, 4000)
      })
    }
  }

  return (
    <Wrapper showGate={showGate}>
      <ScoreWrapper>
        <ColorInputs>
          <div style={{ width: '100%' }}>
            <ColorInputWrapper>
              <Input
                label="Foreground Color"
                placeholder="#000000"
                value={valueA}
                onChange={e => {
                  if (e.target.value.length <= 7) handleInput(e.target.value, 'A')
                }}
                tabIndex={showGate ? '-1' : '0'}
              />
              <ColorPicker
                aria-label="Foreground color picker"
                role="button"
                value={valueA}
                valueColor={valueA}
                onClick={() => setIsPickerAOpen(!isPickerAOpen)}
                tabIndex={showGate ? '-1' : '0'}
              />
              {isPickerAOpen && (
                <div className="popover">
                  <HexColorPicker
                    color={valueA}
                    onChange={color => setValueA(color)}
                    onKeyDown={e => {
                      if (e.key === 'Enter' || e.key === 'Escape') setIsPickerAOpen(false)
                    }}
                    tabIndex={showGate ? '-1' : '0'}
                  />
                  <CloseButton
                    role="button"
                    aria-label="Close color picker"
                    src={CrossIcon}
                    onClick={() => setIsPickerAOpen(false)}
                    onKeyDown={e => {
                      if (e.key === 'Enter' || e.key === 'Escape') setIsPickerAOpen(false)
                    }}
                    tabIndex={showGate ? '-1' : '0'}
                  >
                    <CloseIcon alt="" src={CrossIcon} />
                  </CloseButton>
                </div>
              )}
            </ColorInputWrapper>
            <LuminosityInputWrapper>
              <img src={contrastIcon} alt="luminosity icon" width="18" height="18" />
              <Luminosity
                value={luminosityA}
                type="range"
                aria-label="Foreground color luminosity slider"
                onChange={e => handleLuminosity(e.target.value, 'A')}
                min="0"
                max="100"
                onMouseUp={e => e.currentTarget.blur()}
                tabIndex={showGate ? '-1' : '0'}
              />
            </LuminosityInputWrapper>
          </div>
          <SwapButton
            onClick={() => {
              setValueA(valueB)
              setValueB(valueA)
            }}
            tabIndex={showGate ? '-1' : '0'}
            type="button"
          >
            <SwapBtnIcon src={swapIcon} alt="" width="24" />
            <Text tinyBody color="gray700">
              Swap Colors
            </Text>
          </SwapButton>
          <div style={{ width: '100%' }}>
            <ColorInputWrapper>
              <Input
                label="Background Color"
                placeholder="#FFFFFF"
                value={valueB}
                onChange={e => {
                  if (e.target.value.length <= 7) handleInput(e.target.value, 'B')
                }}
                tabIndex={showGate ? '-1' : '0'}
              />
              <ColorPicker
                aria-label="Background color picker"
                role="button"
                value={valueB}
                valueColor={valueB}
                onClick={() => setIsPickerBOpen(!isPickerBOpen)}
                tabIndex={showGate ? '-1' : '0'}
              />
              {isPickerBOpen && (
                <div className="popover">
                  <HexColorPicker
                    color={valueB}
                    onChange={color => setValueB(color)}
                    onKeyDown={e => {
                      if (e.key === 'Enter' || e.key === 'Escape') setIsPickerBOpen(false)
                    }}
                    tabIndex={showGate ? '-1' : '0'}
                  />
                  <CloseButton
                    role="button"
                    aria-label="Close color picker"
                    src={CrossIcon}
                    onClick={() => setIsPickerBOpen(false)}
                    onKeyDown={e => {
                      if (e.key === 'Enter' || e.key === 'Escape') setIsPickerBOpen(false)
                    }}
                    tabIndex={showGate ? '-1' : '0'}
                  >
                    <CloseIcon alt="" src={CrossIcon} />
                  </CloseButton>
                </div>
              )}
            </ColorInputWrapper>
            <LuminosityInputWrapper>
              <img src={contrastIcon} alt="luminosity icon" width="18" height="18" />
              <Luminosity
                type="range"
                aria-label="Background color luminosity slider"
                value={luminosityB}
                onChange={e => handleLuminosity(e.target.value, 'B')}
                onMouseUp={e => e.currentTarget.blur()}
                tabIndex={showGate ? '-1' : '0'}
                min="0"
                max="100"
              />
            </LuminosityInputWrapper>
          </div>
        </ColorInputs>
        <OverviewPreview contentColor={valueA} backgroundColor={valueB} grayscale={grayscale}>
          <Text role="heading" aria-level="2" smallCaps>
            Preview:
          </Text>
          <Text heading4>This text is considered “large text”</Text>
          <Text smallBody>This text is considered “small text”</Text>
          <div>
            <Text smallBody>This icon is considered a “graphic”</Text>
            <Space height={8} />
            <PersonIcon fill={valueA} />
          </div>
          <div>
            <Text smallBody>This text input field is considered a "user interface (UI)" component</Text>
            <Space height={4} />
            <Text tinyBody>
              <strong>Form Label</strong>
            </Text>
            <Space height={8} />
            <FakeInput disabled />
          </div>
        </OverviewPreview>
        <Ratio>
          <Text color="gray700" smallBodyBold role="heading" aria-level="2">
            Your Contrast Results
          </Text>
          <Text color="gray700" tinyBody aria-hidden="true">
            {pass === false ? 'Your colors do not meet WCAG requirements' : 'Your colors meet WCAG requirements'}
          </Text>
          <RatioText aria-hidden="true">
            {ratio}
            <span>:1</span>
          </RatioText>
          <Text smallBody>
            Enter your text color and background color above to see if your color combination is accessible and
            compliant for those with visual impairments.
          </Text>
          <Button
            text={isCopied ? 'Copied to clipboard!' : 'Share Contrast Results'}
            buttonType="secondary"
            onClick={handleShare}
            tabIndex={showGate ? '-1' : '0'}
            aria-live="polite"
          />
          <div className="sr-only" aria-live="polite">
            <p>{`Your ratio is ${ratio}:1. ${
              pass === false
                ? `Your colors do not meet WCAG requirements. ${level}.`
                : `Your colors meet WCAG requirements. ${level}. `
            }`}</p>
            <p>{`Small text compliance level AA ${ratio >= 4.5 ? 'pass' : 'fail'}. Small text compliance level AAA ${
              ratio >= 7 ? 'pass' : 'fail'
            }.`}</p>
            <p>{`Large text compliance level AA ${ratio >= 3 ? 'pass' : 'fail'}. Large text compliance level AAA ${
              ratio >= 4.5 ? 'pass' : 'fail'
            }.`}</p>
          </div>
        </Ratio>
        <ComplianceLevel>
          <Text color="gray700" smallBodyBold role="heading" aria-level="2">
            Your Compliance
          </Text>
          <Space height={8} tabletHeight={0} />
          <Text smallBodyBold aria-level="3">
            Small Text
          </Text>
          <SubTwoCol>
            <SmallTwoCol>
              <Text tinyBody>Level AA</Text>
              <Tag
                text={ratio >= 4.5 ? 'pass' : 'fail'}
                color={ratio >= 4.5 ? 'green' : 'red'}
                size="small"
                fontSize="tinyBody"
              />
            </SmallTwoCol>
            <SmallTwoCol>
              <Text tinyBody>Level AAA</Text>
              <Tag
                text={ratio >= 7 ? 'pass' : 'fail'}
                color={ratio >= 7 ? 'green' : 'red'}
                size="small"
                fontSize="tinyBody"
              />
            </SmallTwoCol>
          </SubTwoCol>
          <Space height={16} />
          <Text smallBodyBold aria-level="3">
            Large Text
          </Text>
          <SubTwoCol>
            <SmallTwoCol>
              <Text tinyBody>Level AA</Text>
              <Tag
                text={ratio >= 3 ? 'pass' : 'fail'}
                color={ratio >= 3 ? 'green' : 'red'}
                size="small"
                fontSize="tinyBody"
              />
            </SmallTwoCol>
            <SmallTwoCol>
              <Text tinyBody>Level AAA</Text>
              <Tag
                text={ratio >= 4.5 ? 'pass' : 'fail'}
                color={ratio >= 4.5 ? 'green' : 'red'}
                size="small"
                fontSize="tinyBody"
              />
            </SmallTwoCol>
          </SubTwoCol>
          <Space height={16} />
          <Text smallBodyBold aria-level="3">
            Graphics & UI Components
          </Text>
          <SubTwoCol>
            <SmallTwoColSingle>
              <Text tinyBody>Level AA</Text>
              <Tag
                text={ratio >= 3 ? 'pass' : 'fail'}
                color={ratio >= 3 ? 'green' : 'red'}
                size="small"
                fontSize="tinyBody"
              />
            </SmallTwoColSingle>
          </SubTwoCol>
        </ComplianceLevel>
        <GrayscaleControl>
          <GrayscaleToggle
            role="checkbox"
            onClick={() => setGrayscale(!grayscale)}
            tabIndex={showGate ? '-1' : '0'}
            grayscale={grayscale}
            aria-checked={grayscale}
            aria-labelledby="grayscale-label"
          />
          <Text smallBodyBold id="grayscale-label">
            Preview Colors in Grayscale
          </Text>
        </GrayscaleControl>
        <RatioScale>
          <Text color="gray700" smallBodyBold style={{ gridRow: '1 / 2' }} role="heading" aria-level="2">
            Contrast Ratio Scale
          </Text>
          <Tooltip
            text="Contrast ratio refers to how bright or dark colors appear on screens. The more scientific definition is that contrast is a ratio of the luminance of the brightest color to the darkest color that the system can produce. Contrast ratios range from 1 to 21 (written as 1:1 and 21:1). The first number, L1, refers to the relative luminance of light colors while L2 is the second number that refers to the relative luminance of dark colors."
            tabIndex={showGate ? '-1' : '0'}
          />
          <ScaleGradientWrapper>
            <LowWrapper>
              <img src={errorIcon} alt="" width="20" />
              <Text color="grey700" smallCapsSans>
                LOW
              </Text>
            </LowWrapper>
            <ScaleGradientRange>
              <ScaleGradientFill ratio={Math.floor((ratio / 21) * 100)} />
              <YourScoreBox ratio={Math.floor((ratio / 21) * 100)}>
                <Text color="white" tinyBody center>
                  Your Ratio
                </Text>
                <Text color="white" bodyBold center>
                  {ratio}:1
                </Text>
              </YourScoreBox>
            </ScaleGradientRange>
            <HighWrapper>
              <img src={greenCheckIcon} alt="" width="20" />
              <Text color="grey700" smallCapsSans>
                HIGH
              </Text>
            </HighWrapper>
            <RatingWrapper>
              <Rating>
                <Text tinyBody color="gray700">
                  1
                </Text>
              </Rating>
              <Rating>
                <Text tinyBody color="gray700">
                  3
                </Text>
              </Rating>
              <Rating>
                <Text tinyBody color="gray700">
                  4.5
                </Text>
              </Rating>
              <Rating>
                <Text tinyBody color="gray700">
                  7
                </Text>
              </Rating>
              <Rating5>
                <Text tinyBody color="gray700">
                  21
                </Text>
              </Rating5>
            </RatingWrapper>
          </ScaleGradientWrapper>
        </RatioScale>
      </ScoreWrapper>
      <GatedForm showGate={showGate}>
        <ColorContrastCheckerForm setShowGate={setShowGate} />
      </GatedForm>
      <BG className="bg" />
    </Wrapper>
  )
}

export default ContrastChecker
